import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Header from '../components/Header';
import Footer from '../components/Footer';

const url = 'https://emma-siegel.web.app/'

const Home = () => {
  return (
    <div className="bg-gray-50 font-sans">
      <Header />

      <main className="flex flex-col items-center justify-center mt-8 md:mt-16 px-4 md:px-0">
        <div className="flex flex-col md:flex-row items-center md:space-x-8 space-y-8 md:space-y-0">
          <div className="relative w-64 h-64 md:w-[400px] md:h-[400px] rounded-full overflow-hidden">
            <img src={url + 'profile.jpeg'} alt="Portrait of a woman standing outside, wearing a green blazer and shielding her eyes from the sun with a river and cityscape in the background." className="w-full h-full object-cover" />
          </div>
          <div className="md:ml-12 max-w-lg text-center md:text-left">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900">News Editor & Producer.<br/> Content Writer.</h1>
            <p className="mt-4 text-gray-700">I’m Emma Siegel, a seasoned writer and TV news producer with a strong background in broadcast news. I specialize in writing scripts, crafting video news packages, and producing engaging news shows. My career also includes time as a managing editor in the magazine industry, where I honed my editorial and storytelling skills.

With experience across various publications, I’ve developed a versatile range of writing abilities, from SEO-driven content to feature articles. Whether it’s breaking news or long-form narrative, I create content that informs, engages, and drives results.

Explore my portfolio and see how I can bring stories to life.</p>
            <div className="flex flex-wrap justify-center md:justify-start space-x-4 space-y-4 md:space-y-0 mt-6">
              <a href="/resume" className="bg-blue-200 text-gray-800 rounded-full text-lg flex items-center justify-center w-32 h-32 md:w-[160px] md:h-[160px] hover:bg-transparent hover:text-black hover:border-2 hover:border-black transition-all duration-300">Resume</a>
              <a href="/writing" className="bg-blue-300 text-gray-800 rounded-full text-lg flex items-center justify-center w-32 h-32 md:w-[160px] md:h-[160px] hover:bg-transparent hover:text-black hover:border-2 hover:border-black transition-all duration-300">Writing</a>
              <a href="/videos" className="bg-blue-400 text-white rounded-full text-lg flex items-center justify-center w-32 h-32 md:w-[160px] md:h-[160px] hover:bg-transparent hover:text-black hover:border-2 hover:border-black transition-all duration-300">Videos & Scripts</a>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Home;