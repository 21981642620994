import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Home from './pages/Home';
import Writing from './pages/Writing';
import Videos from './pages/Videos';
import Resume from './pages/Resume';
import Contact from './pages/Contact';

function App() {
  return (
    <>
      <Router>
        <Routes>
         <Route path="/contact" element={<Contact />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/writing" element={<Writing />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;