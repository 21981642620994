import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
      <footer className="w-full mt-16 py-8 border-t border-gray-200">
        <div className="flex flex-col sm:flex-row justify-between items-center max-w-4xl mx-auto text-gray-600 text-sm px-4 sm:px-0">
          <div className="mb-6 sm:mb-0 text-center sm:text-left">
            <p><strong>WhatsApp</strong><br/>416.894.1551</p>
            <p><strong>Phone</strong><br/>055-932-3983</p>
            <p className="mt-4"><strong>Email</strong><br/>emma_siegel@hotmail.com</p>
          </div>
          <div className="text-center sm:text-right">
            <p><strong>Let's connect</strong></p>
            <div className="mt-2 space-x-4">
              <a target='_blank' href="https://www.linkedin.com/in/emma-siegel-3b5b121b3/" className="text-gray-600 hover:text-gray-900">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;