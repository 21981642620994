import React from 'react';

// Set up PDF.js worker
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
    const videos = {
        'Copy of HAREDI RIOTS SCRIPT VTR': {
            'text': `IT WAS THE FIRST RECRUITMENT DAY FOR ISRAEL’S ULTRA-ORTHODOX WHO WERE GIVEN DRAFT ORDERS FROM THE IDF. BUT WHILE HUNDREDS SHOWED UP, IT WASN’T TO ENLIST.
WHAT WAS SUPPOSED TO BE A DEMONSTRATION AGAINST ENLISTMENT TURNED INTO VIOLENT RIOTS OUTSIDE OF THE MILITARY CONSCRIPTION OFFICE.
ISRAEL’S ULTRA-ORTHODOX SECTOR OPPOSE MILITARY SERVICE AS THEY CONSIDER THE ARMY A DISTRACTION FROM TORAH STUDY AND A THREAT TO THEIR WAY OF LIFE. FOR DECADES RELIGIOUS LEADERS AND POLITICAL FIGURES FIERCELY RESISTED EFFORTS TO DRAFT YESHIVA STUDENTS, WHO HAVE RECEIVED EXEMPTIONS THEM FROM MILITARY SERVICE SO THEY CAN PURSUE RELIGIOUS STUDY.
BUT, THE HAMAS LED MASSACRE ON OCTOBER 7TH AND SUBSEQUENT WAR DRAGGING INTO ITS TENTH MONTH HAS HEIGHTENED THE DEMANDS FOR THE ULTRA ORTHODOX TO SERVE IN THE ARMY.
19. 02:25-02:40 SOUNDBITE 3 - Jacob, ultra-Orthodox demonstrator (male, Hebrew, 14 sec):
"I've never enlisted. I have an arrest warrant, but it doesn't bother me. Even if I will get arrested I’m willing to pay the price, as it is nothing compared to what we achieve, that no one will change their religion."
ACCORDING TO THE ISRAELI POLICE, RIOTERS ATTEMPTED TO BREAK INTO THE CONSCRIPTION OFFICE, WHILE WREAKING HAVOC OUTSIDE - THROWING OBJECTS AND SPITTING AT THE POLICE. AND ALSO BOOING AT ULTRA-ORTHODOX SOLDIERS IN THE VICINITY.
20. 02:41-03:03 SOUNDBITE 4 - Yehuda Hirsch, ultra-Orthodox demonstrator (male, Hebrew, 22 sec):

 "The Zionist army has decided to hold an enlistment day for ultra-Orthodox. They thought that, this way, they could enlist ultra-Orthodox. But an ultra-Orthodox becomes a Zionist when he joins the army. The only barrier between the ultra-Orthodox becoming Israeli is the army. When he doesn't join, he is not an Israeli, he is not loyal to the state and opposes it."
IN JULY ISRAEL ANNOUNCED IT WOULD SEND SOME 1-THOUSAND DRAFT ORDERS TO ULTRA-ORTHODOX MEN BETWEEN THE AGES OF 18-26, TARGETING ONLY THOSE THAT WERE NOT REGISTERED AS FULL-TIME YESHIVA STUDENTS.
THOUGH NOT FULL TIME STUDENTS OF TORAH, THEIR REFUSAL TO SERVE WAS UNWAVERING.
17. 01:59-02:10 SOUNDBITE 1 - Jacob, ultra-Orthodox demonstrator (male, Hebrew, 10 sec):
"We don't mind losing our lives. We don’t mind getting beaten up, or ending up in hospital or prison. The only thing we refuse is to give up the Jewish soul."
WHILE 450 YOUNG MEN WERE SUMMONED YESTERDAY, ONLY 8% OF THE RECRUITS SHOWED UP, WHILE 10 PROTESTERS WERE ARRESTED, AND THREE POLICE OFFICERS WOUNDED.
`
        },
        'Copy of HOSTAGE PROTEST SCRIPT VTR': {
            'text': `https://youtu.be/CxgnEbowZYc?si=eVs_2wq-DGL37ubU
LEDE: Protests and rallies for the return of the hostages continue in Israel. Last night Tel Aviv saw some 500,000 protesting, making it the biggest demonstration in Israel’s history. More in this report from Emma Siegel (VTR IN)
CGS:
_______
1. JON POLIN, Father of Murdered Hostage Hersh Goldberg-Polin
2. EFRAT MACHIKAWA, Niece of Hostage Gadi Moses
3. ANDREY KOSLOV, Hostage Rescued from Hamas Captivity
6. (SOUNDBITE) (English) JON POLIN, Father of Murdered Hostage Hersh Goldberg-Polin
For 330 days Mama and I sought the proverbial stone that we could turn to save you. Maybe, just maybe your death is the stone, the fuel that will bring home the remaining 101 hostages."
ONE WEEK AFTER THE MURDER OF SIX ISRAELI HOSTAGES IN HAMAS CAPTIVITY, JUST DAYS BEFORE BEING FOUND BY THE IDF, ISRAELIS ARE STILL TEEMING WITH RAGE.
NIGHT AFTER NIGHT, PROTESTERS HAVE TAKEN TO THE STREETS ACROSS THE COUNTRY, DEMANDING A DEAL THAT WILL SEE THE REMAINING 101 HOSTAGES IN GAZA BROUGHT HOME.
5. SOUNDBITE (English) EFRAT MACHIKAWA, Niece of Hostage Gadi Moses
"They were alive. Six young, beautiful people. Innocent people. They were alive. They survived. They held strong. Just as Hersh's (killed hostage Hersh Goldberg-Polin) mom cried, 'stay strong'. He did. They all did. And yet, they were murdered in cold blood.
ACCORDING TO PROTEST ORGANIZERS, SATURDAY NIGHT’S TEL AVIV PROTEST SAW SOME HALF A MILLION DEMONSTRATORS - MAKING IT THE LARGEST EVER PROTEST IN ISRAEL’S HISTORY. ORGANIZERS ALSO SAID THAT SOME 250,000 PROTESTED ACROSS THE REST OF THE COUNTRY.
AT THE TEL AVIV PROTEST, RESCUED HOSTAGE ANDREY KOSLOV TOOK TO THE STAGE. HE SPOKE ABOUT HIS TIME IN CAPTIVITY WITH ALEX LOBANOV, ONE OF THE SIX HOSTAGES EXECUTED BY HIS CAPTORS.
Andrey Koslov, Hostage Rescued from Hamas Captivity

 Alex was with us in hell for two months. The husband of Michal, the father of Tom and Kai. The son that he never got the chance to meet. He taught me words in Hebrew and told me his life story. We shared our fears and hopes. My wishes came true. For him, his fears came alive.
(WHITE FLASH)
I got lucky. I don’t know why but I am blessed. I am here. Hersh, Eden, Carmel, Ori, Almog and Alex, they are not.
AT LEAST TWO PROTESTERS WERE ARRESTED AT THE MASSIVE TEL AVIV RALLY, AS CLASHES BROKE OUT BETWEEN POLICE AND DEMONSTRATORS WHEN ACTIVISTS ATTEMPTED TO BLOCK MAJOR ROADS AND HIGHWAYS.
ON MONDAY MORNING, ISRAELI PRIME MINISTER BENJAMIN NETANYAHU REITERATED HIS WAR GOALS OF ELIMINATING HAMAS AND BRINGING BACK THE HOSTAGES.
BIBI SOT
Last weekend,Germany's "Bild" newspaper published an official document of Hamas showcasing it's plan to sow division within us, engage in psychological warfare about the families of the abductees, exert internal and external political pressure on the government of Israel, tear us apart from the inside and continue the war, until further notice, until the defeat of Israel. The vast majority of Israeli citizens do not fall into this trap of Hamas. They know we are committed in all our efforts to achieve the goals of the war: eliminate Hamas, bring back all our hostages.
THIS AS CIA DIRECTOR WILLIAM BURNS SAYS HE HELD TALKS OVER THE WEEKEND WITH ISRAEL, QATAR AND EGYPT IN AN EFFORT TO REACH A CEASEFIRE DEAL. BURNS SAID ON SATURDAY THAT HE BELIEVES REACHING A DEAL IS QUOTE, ‘A QUESTION OF POLITICAL WILL.’
ELEVEN MONTHS INTO THE WAR, HOSTAGE FAMILIES, PROTESTERS AND MEDIATORS CONTINUE TO LEAVE NO STONE LEFT UNTURNED TO BRING BACK THE 101 HOSTAGES.

 SEPTEMBER 8TH 2023 JUST BEFORE MIDNIGHT, A DEVASTATING 6.8 EARTHQUAKE HIT MOROCCO’S HIGH ATLAS - A MOUNTAIN RANGE IN THE COUNTY’S CENTRE.
AND NOW, ONE YEAR LATER, THE MOUNTAIN COMMUNITIES ARE STILL TRYING, AND STRUGGLING, TO REBUILD.
OVER 55,000 HOMES WERE DESTROYED IN THE STRONGEST QUAKE MOROCCO HAS SEEN SINCE 1960, LEAVING SOME 380,000 PEOPLE WITHOUT SHELTER.
IN SOME PLACES, RESIDENTS HAVE BEGUN TO RECONSTRUCT THEIR HOMES - WHILE STILL ACTUALLY AWAITING GOVERNMENT APPROVAL TO REBUILD.
4. SOUNDBITE (Arabic) Abderrahim Alachoun, Plumber in Amizmiz
"Thank God things have improved a bit, the danger has been removed. We also need to work differently than before, when no one was working and we were suffering. Today, things are gradually getting better. Some residents have benefitted from the [government] support, and others are waiting."
BUT MANY HAVE NOT BEEN SO FORTUNATE. THE MAJORITY OF THE DISPLACED ARE STILL LIVING IN TENTS, VULNERABLE TO THE INTENSE SUMMER HEAT AND FREEZING WINTER CONDITIONS.
52. 05:19-05:49 SOUNDBITE 2 - Yassine Aït Si Mhanned, Resident of Tiniskt Village
"There are still people living in tents and suffering from the heat, and they are certainly at risk of facing the cold here in the tents. There is also the problem of support for people who have completely lost their homes and who have received compensation of 80,000 dirhams, but should this be used to build? To buy land?
SO FAR, ONLY AROUND 1,000 HOMES HAVE BEEN REBUILT.
OFFICIALS SAY THAT RESTORATION WILL COST APPROXIMATELY 12 BILLION U.S. DOLLARS OVER THE SPAN OF FIVE YEARS. WHILE THE GOVERNMENT HAS REBUILT SOME INFRASTRUCTURE DESTROYED LAST YEAR, IT HAS ALSO ACKNOWLEDGED THAT THERE IS A NEED TO SPEED UP HOME REBUILDING. AS RESIDENTS CONTINUE TO WAIT, AND HOPE, FOR THEIR LIVES TO GO BACK TO NORMAL.`
        },
        'Copy of MOROCCO EARTHQUAKE ANNIVERSARY SCRIPT VTR': {
            'text': `LEDE: Morocco is marking one year since a catastrophic earthquake killed almost 3-thousand people and displaced tens of thousands more. While rebuilding efforts are underway, many residents feel that construction is not moving fast enough. More in this report by Emma Siegel (VTR IN)
CGS:
1. ABDERRAHIM ALACHOUN, Plumber in Amizmiz
2. YASSINE AÏT SI MHANNED, Resident of the Village Tiniskt
_____________________________
SEPTEMBER 8TH 2023 JUST BEFORE MIDNIGHT, A DEVASTATING 6.8 EARTHQUAKE HIT MOROCCO’S HIGH ATLAS - A MOUNTAIN RANGE IN THE COUNTY’S CENTRE.
AND NOW, ONE YEAR LATER, THE MOUNTAIN COMMUNITIES ARE STILL TRYING, AND STRUGGLING, TO REBUILD.
OVER 55,000 HOMES WERE DESTROYED IN THE STRONGEST QUAKE MOROCCO HAS SEEN SINCE 1960, LEAVING SOME 380,000 PEOPLE WITHOUT SHELTER.
IN SOME PLACES, RESIDENTS HAVE BEGUN TO RECONSTRUCT THEIR HOMES - WHILE STILL ACTUALLY AWAITING GOVERNMENT APPROVAL TO REBUILD.
4. SOUNDBITE (Arabic) Abderrahim Alachoun, Plumber in Amizmiz
"Thank God things have improved a bit, the danger has been removed. We also need to work differently than before, when no one was working and we were suffering. Today, things are gradually getting better. Some residents have benefitted from the [government] support, and others are waiting."
BUT MANY HAVE NOT BEEN SO FORTUNATE. THE MAJORITY OF THE DISPLACED ARE STILL LIVING IN TENTS, VULNERABLE TO THE INTENSE SUMMER HEAT AND FREEZING WINTER CONDITIONS.
52. 05:19-05:49 SOUNDBITE 2 - Yassine Aït Si Mhanned, Resident of Tiniskt Village
"There are still people living in tents and suffering from the heat, and they are certainly at risk of facing the cold here in the tents. There is also the problem of support for people who have completely lost their homes and who have received compensation of 80,000 dirhams, but should this be used to build? To buy land?
SO FAR, ONLY AROUND 1,000 HOMES HAVE BEEN REBUILT.

 OFFICIALS SAY THAT RESTORATION WILL COST APPROXIMATELY 12 BILLION U.S. DOLLARS OVER THE SPAN OF FIVE YEARS. WHILE THE GOVERNMENT HAS REBUILT SOME INFRASTRUCTURE DESTROYED LAST YEAR, IT HAS ALSO ACKNOWLEDGED THAT THERE IS A NEED TO SPEED UP HOME REBUILDING. AS RESIDENTS CONTINUE TO WAIT, AND HOPE, FOR THEIR LIVES TO GO BACK TO NORMAL.
`
        },
        'Copy of POLIO GAZA VTR SCRIPT': {
            'text': `LEDE: Humanitarian pauses in the Gaza Strip enter the second day of a nine-day polio vaccination drive, following the diagnosis last month of the first case of the disease in the enclave in 25 years. More in this report by Emma Siegel (VTR IN)
CGS:
NEVIN ABU AL-JIDYAN, Gazan resident
WAFAA ABDELHADI Displaced Palestinian Mother __
DAY TWO OF THE MASS POLIO VACCINATION CAMPAIGN IS UNDERWAY IN THE GAZA STRIP.
THIS COMES AFTER A POLIOVIRUS CASE WAS RECORDED IN THE ENCLAVE FOR THE FIRST TIME IN 25 YEARS.
5. SOUNDBITE (Arabic) Nevin Abu Al-Jidyan, Gazan resident:
"Then I got a call from the local health authorities. They said my son is the first confirmed case of polio in Gaza (in 25 years)."
HER SON TURNED 1 YEAR OLD ON SEPTEMBER 1ST.
8. SOUNDBITE (Arabic) Nevin Abu Al-Jidyan, Gazan resident:
"He is one year old on Sept 1. Most children of his age have started to learn how to walk, but he cannot even move."
HEALTH WORKERS HAVE SINCE LAUNCHED A VACCINATION DRIVE TO WARD OFF A FEARED POLIO OUTBREAK. BOTH ISRAEL AND HAMAS HAVE AGREED TO A PARTIAL PAUSE IN FIGHTING FOR DAYS AT A TIME IN SPECIFIC AREAS OF THE STRIP FOR LOCAL HEALTH OFFICIALS AND THE UNITED NATIONS TO ADMINISTER THE VACCINATIONS.
DESCRIBED AS “HUMANITARIAN PAUSES,” THE WORLD HEALTH ORGANIZTION AND UNICEF, IN COORDINATION WITH THE IDF’S SOUTHERN COMMAND AND THE AGENCY COGAT, ARE AIMING TO USE THIS TIME TO PROVIDE ORAL VACCINES TO MORE THAN 640,000 CHILDREN UNDER THE AGE OF 10.
5. (SOUNDBITE) (Arabic) DISPLACED PALESTINIAN MOTHER, WAFAA ABDELHADI, 28, SAYING:
"Thank God, I am happy that I vaccinated my children against polio so that they don't suffer from anything in the future God willing. Hopefully all Gazan children get vaccinated not only against polio, but with other vaccinations too."
BUT, IT IS NOT JUST GAZANS WHO ARE IN NEED OF VACCINES. LAST WEEK, THE HEAD OF ISRAEL’S ASSOCIATION OF PUBLIC HEALTH PHYSICIANS
   
 CALLED ON THE UN TO MAKE SURE ISRAELI HOSTAGES IN GAZA ARE INCLUDED IN THE CAMPAIGN.
THE TWO YOUNGEST HOSTAGES, ARIEL BIBAS, 5 YEARS OLD, AND KFIR BIBAS, JUST OVER ONE, ARE ALSO IN NEED OF THE POLIO VACCINATION.
POLIOVIRUS IS HIGHLY INFECTIOUS AND CAN CAUSE PARALYSIS AND DEATH IN ITS MOST SEVERE CASES.
IT’S MOST OFTEN SPREAD THROUGH SEWAGE AND CONTAMINATED WATER - SOMETHING THAT HAS BECOME AN INCREASING PROBLEM IN THE GAZA STRIP AS THE ISRAEL-HAMAS WAR RAGES ON FOR THE ELEVENTH MONTH.
`
        },
        'Copy of QATAR WORLD CUP MUSEUM VTR SCRIPT': {
            'text': `LEDE: WITH THE WORLD CUP JUST A FEW MONTHS AWAY, QATAR IS GETTING READY TO HOST WITH A NEW SPORTS MUSEUM FOR FANS COMING FROM ALL OVER THE WORLD. OUR EMMA SIEGEL HAS THE STORY:
CGS:
1) ABDULLA YOUSUF Director of 3-2-1 Qatar Olympic and Sports Museum
STORY:
QATAR IS GEARING UP TO HOST THE UPCOMING WORLD CUP LATER THIS YEAR BY OPENING A NEW MUSEUM DEDICATED TO SPORTS.
CALLED THE OLYMPIC AND SPORTS MUSEUM, IT’S ONE OF THE LARGEST OF ITS KIND IN THE WORLD AT AROUND 19,000 SQUARE METRES. SPANNING FIVE FLOORS, IT CONTAINS SEVERAL GALLERIES, A GIFT SHOP, A LIBRARY, RESEARCH AREA AND AN AUDITORIUM.
18. (SOUNDBITE) (English) DIRECTOR OF 3-2-1 QATAR OLYMPIC AND SPORTS MUSEUM, ABDULLA YOUSUF AL MULLA, SAYING:
"It's really showcasing the country and it is not only for this generation but also for the upcoming generations and they will witness that you know the people of Qatar, the leader of Qatar, they are really taking the sport as an excellent element to showcase the country."
IT WILL BE OPEN TO SPORTS FANS THROUGHOUT THE DURATION OF THE WORLD CUP AND WILL HAVE A SPECIAL EXHIBIT DEDICATED TO THE TOURNAMENT.
25. (SOUNDBITE) (English) DIRECTOR OF 3-2-1 QATAR OLYMPIC AND SPORTS MUSEUM, ABDULLA YOUSUF AL MULLA, SAYING:
"We are going to host a big football exhibition during the World Cup and it will last for six months, it will be open from first of October 2022 until 31 March 2023, it is going to be the history of football in the world."
    
 8. (SOUNDBITE) (English) DIRECTOR OF 3-2-1 QATAR OLYMPIC AND SPORTS MUSEUM, ABDULLA YOUSUF AL MULLA, SAYING:
“Everybody is welcome there and in the museum, I believe they will have a good experience and they will have good memories when they go back home."
HOSTED FOR THE FIRST TIME BY A MIDDLE EASTERN COUNTRY, THE WORLD CUP IS SET TO BEGIN IN NOVEMBER 2022.
The museum's doors will be open to fans throughout the tournament, apart from match days at the stadium, for a 15US dollar entry fee.
It will be the first World Cup staged in the Middle East and has been pushed back to later in the year than its typical June-July schedule to avoid the region's punishing summer heat.
25. (SOUNDBITE) (English) DIRECTOR OF 3-2-1 QATAR OLYMPIC AND SPORTS MUSEUM, ABDULLA YOUSUF AL MULLA, SAYING:
"We are going to host a big football exhibition during the World Cup and it will last for six months, it will be open from first of October 2022 until 31 March 2023, it is going to be the history of football in the world."
28. (SOUNDBITE) (English) DIRECTOR OF 3-2-1 QATAR OLYMPIC AND SPORTS MUSEUM, ABDULLA YOUSUF AL MULLA, SAYING:
everybody is welcome there and in the museum, I believe they will have a good experience and they will have good memories when they go back home."
31. (SOUNDBITE) (Arabic) MUSEUM VISITOR FROM SAUDI ARABIA, KHALED WEHBI, SAYING:
          
  "We weren't expecting this museum to be this beautiful and so well organised, we were shocked (positively) honestly, we were shocked by the way they are exhibited, the facilities in this museum, the behaviour of the staff, the cleanliness, everything is clear, if you need help they are there to provide it, the museum was beyond and above our expectations honestly."
  `
        },
        'Copy of SYRIA OLIVE OIL SCRIPT VTR': {
            'text': ` LEDE: AFTER YEARS OF ECONOMIC AND POLITICAL INSTABILITY, AS WELL AS THE DETRIMENTAL IMPACTS OF CLIMATE CHANGE, SYRIA’S OLIVE OIL INDUSTRY IS MAKING A COMEBACK. I24NEWS’ EMMA SIEGEL HAS MORE IN THIS NEXT REPORT:
CGS:
1) Head of Olive Department in Syrian Agriculture Ministry ABIR JAWHAR
AFTER 11 YEARS OF STEEP DECLINE, SYRIA’S OLIVE INDUSTRY HAS SEEN A BETTER-THAN EXPECTED HARVEST IN 2022, A SIGN OF HOPE FOR THE RENOWNED GLOBAL EXPORT.
THOUGH THE OLIVE OIL PRODUCTION IS EXPECTED TO BE AROUND 125,000 TONNES – A MASSIVE SURGE FROM LAST YEARS 80,000 – IT IS STILL FAR AWAY FROM THE PRE-WAR LEVELS.
SOT
“It was ranked fourth in the world in terms of production in the 2011-2012 season, with olive oil production reaching 198,000 tons. Unfortunately, we have now declined due to low productivity and climate change and not being able to give the olive tree the necessary care for it to have a high or abundant production."
WHILE A COMBINATION OF THE CIVIL WAR IN SYRIA, SANCTIONS THAT GUTTED ITS ECONOMY AND CLIMATE CHANGE HAVE AFFECTED THE EXPORT, THE BIGGEST CHALLENGE FACING FARMERS IS THE HIGH PRICE OF FERTILISERS AND A LACK OF FUEL.
THE INSTABILITY HAS LED TO SYRIAN OLIVE OIL BEING OFFERED AT PRICES TOO HIGH TO GAIN COMPETITIVENESS ON A GLOBAL SCALE.
SOT
“If the requirements are available and the sanctions are removed, it will be easy to secure the necessities at reasonable prices so that we can provide the tree with the needed care. Surely this will help the production become better."
WITH THE HOPES OF EXPORTING TO COUNTRIES IN EUROPE, THE U.S., ASIA AND GULF COUNTRIES, THIS MIGHT BE JUST THE BEGINNING OF SYRIA’S OLIVE OIL RESURGENCE.
`
        },
        // 'Copy of TURKEY DOGS VTR SCRIPT',
        'Copy of TURKEY SYRIA VTR': {
            'text': `A VIOLENT NIGHT IN TURKEY AND SYRIA.
ACCUSATIONS THAT A SYRIAN MAN LIVING IN CENTRAL TURKEY ABUSED A CHILD ERUPTED INTO OVERNIGHT RIOTS THAT TARGETED SYRIAN BUSINESSES IN THE ANATOLIAN CITY.
THE TURKISH INTERIOR MINISTER SAID OVER 450 RIOTERS WERE ARRESTED. LEAVING IN THEIR WAKE OVERTURNED CARS AND BUSINESSES SET ABLAZE.
THESE CLASHES SPILLED OVER INTO TURKISH CONTROLLED AREAS OF NORTHERN SYRIA, WHERE HUNDREDS OF OUTRAGED SYRIANS PROTESTED AGAINST TURKEY AND THE ACTIONS OF ITS PEOPLE.
THE SYRIAN BORDER CITY OF AFRIN WAS THE EPICENTER OF THE MOST VIOLENT CLASHES, WITH AT LEAST 4 KILLED IN EXCHANGES OF FIRE BETWEEN ARMED PROTESTERS AND TURKISH TROOPS.
SOUNDBITE (Arabic) Ahmed Shukeir, protester:
"There were different actions by the Turkish government that triggered people here. One of these acts is empowering racism against Syrians in Turkey. Whether it was what happened in Kaysiri, or Gazintap before that. These incidents are supported by Turkish regime to pressure our areas to reach a point of normalization with Assad regime. But hopefully, this will never happen.
IN WAKE OF THE VIOLENT NIGHT, TURKEY HAS CLOSED ITS MAIN BORDER CROSSING INTO NORTHWEST SYRIA UNTIL FURTHER NOTICE. THIS BORDER IS A MAIN TRADE AND PASSENGER CONDUIT FOR MORE THAN THREE MILLION INHABITANTS.
MEANWHILE, TURKISH PRESIDENT ERDOGAN HAS BLAMED OPPOSITION PARTIES IN TURKEY FOR THE RAMPAGE, POINTING A FINGER AT THEM FOR “HATRED POLITICS.”
THERE ARE APPROXIMATELY 3.6 MILLION SYRIAN REFUGEES LIVING IN TURKEY.
BUT AN-KUH-RA HAS OFTEN STRUGGLED TO PROPERLY INTEGRATE THEM INTO TURKISH SOCIETY. THIS, WITH A STRUGGLING ECONOMY, HAS TURNED THE REFUGEE CRISIS INTO A CENTRAL DEBATE IN TURKISH POLITICS.
8. (SOUNDBITE) (Turkish) TURKISH PRESIDENT, TAYYIP ERDOGAN, SAYING:
“No one can reach anywhere by fueling xenophobia and hate towards refugees. One of the reasons for the saddening events caused by a small group in Kayseri is the opposition’s poisonous discourse. No matter who it is, burning people’s homes and their relatives, engaging in vandalism, setting fire to the streets is unacceptable.”

 THESE OVERNIGHT SKIRMISHES COME JUST DAYS AFTER ERDOGAN SAID HE WAS OPEN TO MEETING WITH SYRIAN PRESIDENT ASSAD, IN A FIRST STEP TO RESTORING TIES BETWEEN THE TWO COUNTRIES.
BUT WHILE ITS LEADERS MIGHT BE OPEN TO NORMALISING TIES AGAIN, THE QUESTION IS, WILL IT’S PEOPLE FOLLOW SUIT? (END WITH RIOT SHOTS)
`
            
        },
        // 'Copy of UNRWA SCRIPT VTR',
        // 'Copy of WEST BANK LATEST VTR SCRIPT',
        'Copy of GAZA LATEST WHERE IS SINWAR VTR SCRIPT': {
            'text': ` LEDE: MISSING
50 TERROR SITES DEMOLISHED IN GAZA CITY’S SA- JA- EE-A OVER THE PAST DAY.
OVER 900 TERRORISTS KILLED THROUGHOUT THE RAFAH GROUND OPERATION.
THESE ARE THE NUMBERS THE IDF HAS RELEASED IN THE PAST DAY.
1. (SOUNDBITE) (Hebrew) IDF CHIEF OF STAFF HERZI HALEVI, SAYING:
"In the Rafah Brigade, what we’ve seen with our own eyes, and I don't want to say something out of thin air, we count over 900 terrorists killed, including commanders, at least one battalion commander, many company commanders and many operatives. The current effort, and the reason we are working here week after week, is now focused on the destruction of the terrorist infrastructure and the destruction of the underground infrastructure, which takes time."
BUT WHILE THE IDF IS MOVING DETERMINDEDLY THROUGHOUT THE GAZA STRIP TO ACHIEVE ITS GOALS, ONE QUESTION REMAINS: WHERE IS HAMAS LEADER YAHYA SINWAR?
TERROR SOURCES TOLD A LONDON BASED, ARABIC LANGUAGE NEWSPAPER THAT ONLY ‘TWO OR THREE’ PEOPLE KNOW SINWAR’S WHEREABOUTS. THIS REPORT DID NOT SPECIFY WHETHER HE WAS HIDING ABOVE GROUND OR IN THE TUNNELS SNAKING UNDERNEATH GAZA.

 SOURCES ALSO TOLD THE SAUDI-OWNED OUTLET THAT THEY DON’T THINK SINWAR WILL EXCEPT ACCEPT A DEAL THAT SEES HIM EXILED. GOING AS FAR TO SAY, SUCH A PROPOSAL WOULD BE QUOTE ‘FUNDAMENTALLY UNACCEPTABLE’ FOR SINWAR.
MEANWHILE, ISRAELI PRIME MINISTER LAST NIGHT SLAMMED A NEW YORK TIMES REPORT WHICH ALLEGED ISRAEL WOULD BE WILLING TO END THE WAR BEFORE ACHIEVING ALL OF ITS GOALS.
1. (SOUNDBITE) (Hebrew) ISRAELI PRIME MINISTER BENJAMIN NETANYAHU SAYING:
I don't know who those unnamed officials are, but I am here to convey unequivocally that this is not going to happen. We will end the war only after we achieve all of its goals, including the elimination of Hamas and the release of all of our hostages. The political echelon defined these goals to the IDF and the IDF has all the means to achieve them. We do not give in to the winds of defeat, not in the New York Times and not anywhere else. We are imbues with the spirit of victory.
BUT AS ISRAEL HAS PLACED THE ELIMINATION OF SINWAR AS ONE OF ITS TOP GOALS, AND THE TERROR LEADER CONTINUES TO EVADE THE IDF, IT REMAINS TO BE SEEN EXACTLY HOW AND WHEN THIS WAR WILL END.`
        },
    }
    const url = 'https://emma-siegel.web.app/'
return (
    <div className="bg-gray-50 font-sans">
      <Header />

      <main class="flex flex-col items-center justify-center py-16 min-h-screen">
        <div class="grid grid-cols-1 gap-16 max-w-7xl w-full px-4">
        {Object.keys(videos).map((item) => {
                return (
                    <div key={item} className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
                        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col h-[400px]">
                            <h2 className="text-xl font-normal mb-4">
                                {item.replace('Copy of', '').replace('VTR', '').replace('SCRIPT', '')}
                            </h2>
                            <div className="flex-grow flex items-center justify-center">
                                <video 
                                    className="w-full h-full object-contain" 
                                    controls
                                >
                                    <source src={`${url}vtrs/${item}.mp4`} type="video/mp4" />
                                    Your browser does not support HTML video.
                                </video>
                            </div>
                        </div>
                        
                        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col h-[400px]">
                        <h2 className="text-xl font-bold mb-4">Script</h2>
                        <div className="flex-grow overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                            <pre className="whitespace-pre-wrap break-words">
                            {videos[item]['text']}
                            </pre>
                        </div>
                        </div>
                    </div>
                )
            })}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Home;