import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="flex flex-col sm:flex-row justify-between items-center px-4 sm:px-10 py-4 sm:py-6">
      <div className="font-bold text-lg mb-4 sm:mb-0"></div>
      <nav className="text-sm text-gray-700 flex flex-wrap justify-center">
        <a href="/" className={`mx-2 my-1 ${isActive('/') ? 'underline' : 'hover:underline'}`}>Home</a>
        <span className="mx-2 hidden sm:inline sm:mt-1">|</span>
        <a href="/writing" className={`mx-2 my-1 ${isActive('/writing') ? 'underline' : 'hover:underline'}`}>Writing</a>
        <span className="mx-2 hidden sm:inline sm:mt-1">|</span>
        <a href="/videos" className={`mx-2 my-1 ${isActive('/videos') ? 'underline' : 'hover:underline'}`}>Videos & Scripts</a>
        <span className="mx-2 hidden sm:inline sm:mt-1">|</span>
        <a href="/resume" className={`mx-2 my-1 ${isActive('/resume') ? 'underline' : 'hover:underline'}`}>Resume</a>
        <span className="mx-2 hidden sm:inline sm:mt-1">|</span>
        <a href="/contact" className={`mx-2 my-1 ${isActive('/contact') ? 'underline' : 'hover:underline'}`}>Contact</a>
      </nav>
    </header>
  );
};

export default Header;