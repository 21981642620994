import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50 font-sans">
      <Header />

      <main className="flex-grow">
        <div className="max-w-4xl mx-auto p-8 flex items-center justify-center h-full">
          <div className="max-w-xl w-full">
            <h1 className="text-5xl font-bold mb-4">Contact</h1>  

            <div className="mb-6">
              <h2 className="text-2xl font-semibold">WhatsApp</h2>
              <p className="text-xl">416.894.1551</p>
            </div>

            <div className="mb-6">
              <h2 className="text-2xl font-semibold">Phone</h2>
              <p className="text-xl">055-932-3983</p>
            </div>

            <div className="mb-6">
              <h2 className="text-2xl font-semibold">Email</h2>
              <p className="text-xl">emma_siegel@hotmail.com</p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold">LinkedIn</h2>
              <p className="text-xl"><a target="_blank" href="https://www.linkedin.com/in/emma-siegel-3b5b121b3/">https://www.linkedin.com/in/emma-siegel-3b5b121b3/</a></p>
            </div>

          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Home;