import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="bg-gray-50 font-sans">
      <Header />

      <div class="max-w-4xl mx-auto p-8">
            <h1 class="text-5xl font-bold mb-4">Resume</h1>

            <div class="max-w-4xl mx-auto p-8">
        <h1 class="text-3xl font-bold mb-8">Professional Experience</h1>
        <div class="space-y-8">
            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2023 - Present</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">i24NEWS - Producer</h3>
                    <ul class="list-disc pl-5">
                        <li>Produce i24NEWS shows, including hourly updates and the flagship evening broadcast.</li>
                        <li>Write and produce news packages on topics ranging from the Middle East to international affairs.</li>
                        <li>Coordinate cross-functional teams to develop show rundowns.</li>
                        <li>Collaborate with correspondents, anchors, and broadcast personnel to ensure seamless production.</li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2022 - 2023</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">i24NEWS - Associate Producer</h3>
                    <ul class="list-disc pl-5">
                        <li>Supported production of the daily show 'Zoom In' in a fast-paced, deadline-driven newsroom.</li>
                        <li>Pitched impactful stories tailored to the 'Zoom In' audience.</li>
                        <li>Trained new hires on editing software and newsroom protocols.</li>
                        <li>Edited daily headlines and produced content for commercial breaks.</li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2022</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">i24NEWS - Associate Producer Intern</h3>
                    <ul class="list-disc pl-5">
                        <li>Assisted in producing hourly news shows in a high-pressure environment.</li>
                        <li>Edited footage, created visuals, and produced content using Dalet.</li>
                        <li>Contributed to scriptwriting and headline development.</li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2021 - 2022</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">Youth Mind Magazine, Managing Editor</h3>
                    <ul class="list-disc pl-5">
                        <li>Assigned, edited, and finalized all content for the website and magazines.</li>
                        <li>Supervised and managed the editorial staff across multiple divisions.</li>
                        <li>Collaborated cross-functionally to ensure cohesive content and brand strategy.</li>
                        <li>Led the site rebuild and rebranding efforts using WordPress.</li>
                        <li>Monitored website traffic and optimized SEO to increase visibility.</li>
                        <li>Produced biweekly newsletters to engage subscribers with news and updates.</li>
                        <li>Created and maintained production schedules, managing the team's workflow efficiently.</li>
                        <li>Supported the hiring process by conducting interviews in partnership with HR.</li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2020 - 2021</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">Youth Mind Magazine - Contributing Editor</h3>
                    <ul class="list-disc pl-5">
                        <li>Pitched, assigned, and edited stories across various beats.</li>
                        <li>Managed the health, environment, and lifestyle sections of the magazine.</li>
                        <li>Wrote articles as needed to meet content goals.</li>
                        <li>Managed journalists' timelines and enforced deadlines for magazine production.</li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2021</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">Emerge Magazine - Consulting Editor</h3>
                    <ul class="list-disc pl-5">
                        <li>Mentored and provided editorial consultancy for the 2021 edition of Emerge Magazine.</li>
                        <li>Edited content for style, substance, and accuracy, ensuring high-quality publication standards.</li>
                        <li>Managed editorial timelines and consistently met all deadlines.</li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2020</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">Emerge Magazine - Co-Editor-in-Chief</h3>
                    <ul class="list-disc pl-5">
                        <li>Led the production of the annual, internationally award-winning Emerge magazine as part of the fourth-year capstone project.</li>
                        <li>Evaluated, approved, and refined pitches to align with the magazine's theme and concept.</li>
                        <li>Edited all approved content, ensuring adherence to strict deadlines.</li>
                        <li>Oversaw production and established effective communication systems for the print magazine team.</li>
                        <li>Achievements:
                            <ul class="list-disc pl-5">
                                <li>Winner of Gold Crown Award: Hybrid General Magazine.</li>
                                <li>First place winner of Gold Circle Awards: Headline Writing.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2019</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">Rumble Magazine - Journalism Intern</h3>
                    <ul class="list-disc pl-5">
                        <li>Conducted interviews and wrote articles on emerging creators in Toronto.</li>
                        <li>Represented the magazine at events, networking with industry professionals.</li>
                        <li>Consistently met deadlines for article submissions.</li>
                        <li>Used Squarespace to publish stories and manage online content.</li>
                        <li>Proofread articles to ensure accuracy and quality before publication.</li>
                    </ul>
                </div>
            </div>

            <h2 class="text-2xl font-bold mt-12 mb-6">Volunteer Experience</h2>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2021 - 2022</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">INKspire - Editor</h3>
                    <ul class="list-disc pl-5">
                        <li>Pitched stories that aligned with monthly themes and editorial goals.</li>
                        <li>Edited articles for substance, clarity, and adherence to the publication's style guide.</li>
                        <li>Conducted thorough fact-checking to ensure accuracy and credibility.</li>
                        <li>Mentored young writers, providing guidance and feedback to help them develop their skills.</li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2021</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">The Sports Shot - Sports Journalist</h3>
                    <ul class="list-disc pl-5">
                        <li>Pitched and wrote content focused on the NWHL beat.</li>
                        <li>Published stories using WordPress, ensuring timely updates.</li>
                        <li>Consistently met deadlines for article submissions.</li>
                    </ul>
                </div>
            </div>

            <div class="flex space-x-8">
                <div class="text-gray-500 w-1/4">
                    <p>2018 - 2019</p>
                </div>
                <div class="w-3/4">
                    <h3 class="text-xl font-bold">GH360 (university publication) - Writer</h3>
                    <ul class="list-disc pl-5">
                        <li>Created award-winning journalistic content using WordPress platform.</li>
                        <li>Wrote research-driven stories on a variety of topics.</li>
                        <li>Conducted in-person and phone interviews to gather factual information for articles.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </div>
    

      <Footer />
    </div>
  );
};

export default Home;