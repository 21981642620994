import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
  const articles = {
    'YOUTH MIND MAGAZINE': {
      'MAGAZINES': [
        {
          Url: "https://www.theyouthmind.ca/issues/volume-1-issue-04-summer-2021/",
          name: "Volume 1 Issue 04 Summer 2021"
        },
        {
          Url: "https://www.theyouthmind.ca/issues/volume-2-issue-01-fall-2021/",
          name: "Volume 2 Issue 01 Fall 2021"
        },
        {
          Url: "https://www.theyouthmind.ca/issues/volume-2-issue-02-winter-2021/",
          name: "Volume 2 Issue 02 Winter 2021"
        },
        {
          Url: "https://www.theyouthmind.ca/issues/volume-2-issue-03-spring-2022-2/",
          name: "Volume 2 Issue 03 Spring 2022"
        },
        {
          Url: "https://www.theyouthmind.ca/issues/volume-2-issue-04-summer-2022/",
          name: "Volume 2 Issue 04 Summer 2022"
        }
      ],
      'ARTICLES': [
        {
          Url: "https://www.theyouthmind.ca/overtourism-a-lesson-from-the-pandemic/",
          name: "Overtourism A Lesson From The Pandemic"
        },
        {
          Url: "https://www.theyouthmind.ca/letter-from-the-editor-fall-2021-edition/",
          name: "Letter From The Editor Fall 2021 Edition"
        },
        {
          Url: "https://www.theyouthmind.ca/letter-from-the-editor-winter-2021-edition/",
          name: "Letter From The Editor Winter 2021 Edition"
        },
        {
          Url: "https://www.theyouthmind.ca/letter-from-the-editor-spring-2022-edition/",
          name: "Letter From The Editor Spring 2022 Edition"
        },
        {
          Url: "https://www.theyouthmind.ca/letter-from-the-editor-summer-2022-edition/",
          name: "Letter From The Editor Summer 2022 Edition"
        }
      ]
    },
    'NEWCOMER MAGAZINE': [
      {
        Url: "https://thenewcomer.ca/life-in-canada/the-many-faces-on-canadian-money/",
        name: "The Many Faces On Canadian Money"
      },
      {
        Url: "https://thenewcomer.ca/life-in-canada/eating-your-way-through-canada/",
        name: "Eating Your Way Through Canada"
      },
      {
        Url: "https://thenewcomer.ca/arts-culture-history/how-a-group-of-artists-helped-shape-canadas-identity/",
        name: "How A Group Of Artists Helped Shape Canadas Identity"
      },
      {
        Url: "https://thenewcomer.ca/success-stories/remember-where-you-came-from-elisabeth-lengema-finds-balance-while-flourishing-in-canada/",
        name: "Remember Where You Came From Elisabeth Lengema Finds Balance While Flourishing In Canada"
      },
      {
        Url: "https://thenewcomer.ca/life-in-canada/a-guide-to-your-first-winter-in-canada/",
        name: "A Guide To Your First Winter In Canada"
      }
    ],
    'EMERGE MAGAZINE': [
      {
        'Url': 'https://www.emergemagazine.ca/2020/emerge-print-magazine-2020/#fb0=52',
        name: 'The Tipping Point'
      }
    ],
    'GH360': [
      {
        Url: "https://www.gh360.ca/?p=8836",
        name: "GH360-8836"
      },
      {
        Url: "https://www.gh360.ca/?p=7533",
        name: "GH360-7533"
      },
      {
        Url: "https://www.gh360.ca/?p=7348",
        name: "GH360-7348"
      },
      {
        Url: "https://www.gh360.ca/?p=5170",
        name: "GH360-5170"
      },
      {
        Url: "https://www.gh360.ca/?p=7338",
        name: "GH360-7338"
      },
      {
        Url: "https://www.gh360.ca/?p=8055",
        name: "GH360-8055"
      }
    ]
  }

  const url = 'https://emma-siegel.web.app/'

  return (
    <div className="bg-gray-50 font-sans">
      <Header />

      <main className="flex flex-col items-center py-16 min-h-screen">
        <div className="grid grid-cols-1 gap-12 max-w-7xl w-full px-4">
            <div className="flex flex-col space-y-4">
                <h1 className="text-3xl font-bold text-gray-800">YOUTH MIND MAGAZINE</h1>
                <h2 className="text-2xl font-bold text-gray-800">ENTIRE MAGAZINE</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {articles['YOUTH MIND MAGAZINE']['MAGAZINES'].map((item, number) => {
                      return (
                        <a href={item['Url']} target="_blank">
                          <div class="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-96">
                              <div class="h-4/5 overflow-hidden">
                                  <img src={url + 'youth/' + (number + 1).toString() + '.png'}
                                      alt="News Item 1" 
                                      class="w-full h-full object-contain"/>
                              </div>
                              <div class="p-4 h-1/5">
                                  <p class="font-bold text-sm text-center">{item['name']}</p>
                              </div>
                          </div>
                        </a>
                      )
                    })}
                </div>
                <h2 className="text-2xl font-bold text-gray-800">INDIVDUAL ARTICLES</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {articles['YOUTH MIND MAGAZINE']['ARTICLES'].map((item, number) => {
                      return (
                        <a href={item['Url']} target="_blank">
                        <div class="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-96">
                            <div class="h-4/5 overflow-hidden">
                            <img src={url + 'youth-articles/' + (number + 1).toString() + '.jpg'}
                                    alt="News Item 1" 
                                    class="w-full h-full object-cover"/>
                            </div>
                            <div class="p-4 h-1/5">
                                <p class="font-bold text-sm text-center">{item['name']}</p>
                            </div>
                        </div>
                        </a>
                      )
                    })}
                </div>
            </div>
            <h1 className="text-3xl font-bold text-gray-800">NEWCOMER MAGAZINE</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {articles['NEWCOMER MAGAZINE'].map((item, number) => {
                  return (
                    <a href={item['Url']} target="_blank">
                    <div class="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-96">
                        <div class="h-4/5 overflow-hidden">
                        <img src={url + 'newcomer/' + (number + 1).toString() + '.webp'}
                                alt="News Item 1" 
                                class="w-full h-full object-cover"/>
                        </div>
                        <div class="p-4 h-1/5">
                            <p class="font-bold text-sm text-center">{item['name']}</p>
                        </div>
                    </div>
                    </a>
                  )
                })}
            </div>
            <h1 className="text-3xl font-bold text-gray-800">EMERGE MAGAZINE</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {articles['EMERGE MAGAZINE'].map((item) => {
                  return (
                    <a href={item['Url']} target="_blank">
                    <div class="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-96">
                        <div class="h-4/5 overflow-hidden">
                            <img src={url + 'emerge.png'} 
                                alt="News Item 1" 
                                class="w-full h-full object-contain"/>
                        </div>
                        <div class="p-4 h-1/5">
                            <p class="font-bold text-sm text-center">{item['name']}</p>
                        </div>
                    </div>
                    </a>
                  )
                })}
            </div>
            <h1 className="text-3xl font-bold text-gray-800">GH-360</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {articles['GH360'].map((item, number) => {
                  return (
                    <a href={item['Url']} target="_blank">
                    <div class="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-96">
                        <div class="h-4/5 overflow-hidden">
                        <img src={url + 'gh360/' + (number + 1).toString() + '.png'}
                                alt="News Item 1" 
                                class="w-full h-full object-cover"/>
                        </div>
                        <div class="p-4 h-1/5">
                            <p class="font-bold text-sm text-center">{item['name']}</p>
                        </div>
                    </div>
                    </a>
                  )
                })}
            </div>
            
        </div>
    </main>

      <Footer />
    </div>
  );
};

export default Home;